materialAdmin	
	//Roles
	.controller('rolesController', ['$http','$scope','dsGridOptions',function($http,$scope,dsGridOptions) {
		var stateValues = [{value:-1, text:'Bloqueado'}, {value:0, text:'Inactivo'}, {value:-1, text:'Activo'}];
		
		var rolesDataSource = dsGridOptions.getDataSource("rolesGridOptions",'api/roles');
		
		$scope.gridOptions = dsGridOptions.getOptions("rolesGridOptions",{
			 dataSource:rolesDataSource ,
			 
			columns: [
				  
				 { field:'id',   title:'ID', width: 100   },
				 { field:'display_name',   title:'Nome',  width: 200   },
				 { field:'name',   title:'Identificador' ,  width: 100     },
				  {  title:' ', columnMenu:false, sortable:false, filterable:false, template: "<a class=\"btn btn-primary waves-effect\" data-ui-sref=\"users.roles.edit({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-edit\"></i></a>"  ,width:50   } 
				  
				
				 
			]
		});
		
		$scope.toggleActivated = function(item_id) {
			console.log(item_id);
		}
		$scope.toggleState = function(item_id) {
			console.log(item_id);
		}
	}])
	
	
	.controller('roleController', ['$state','$controller','$scope','$stateParams','Role','editorFunctions', function($state,$controller,$scope,$stateParams,Role,editorFunctions) {
		  
		$scope.hasImageUpload = false;
		
		angular.extend(this, $controller('editorController', {$scope: $scope})); 
		editorFunctions.setSuccessUrl("users.roles.index");  
		editorFunctions.setFormName("roleForm");  
		editorFunctions.setValidator("eventformvalidateapi");  
		 
		
		  // console.log( $stateParams.id );
     	  if( $stateParams.id ) {
			$scope.role = Role.get({ id: $stateParams.id } ); 
		  } else {
			 $scope.role = new Role( {state:1 }); 
		  } 
		  
		  $scope.eventformvalidate = {
			rules: {
				 
			},
			messages: {
			},
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				 
				$scope.role.saveOrUpdate(editorFunctions.saveSuccess,editorFunctions.saveError);	 
			}
		};
		  
		 
	}])
	
	
	//User
	.controller('usersController', ['$http','$scope','growlService','dsGridOptions', function($http,$scope,growlService, dsGridOptions) {
		var stateValues = [{value:-1, text:'Bloqueado'}, {value:0, text:'Inactivo'}, {value:-1, text:'Activo'}]
		
		
		
		var usersDataSource = dsGridOptions.getDataSource("usersGridOptions",'api/users');
		
		$scope.gridOptions = dsGridOptions.getOptions("usersGridOptions",{
			 dataSource:usersDataSource , 
			height: function() { return Math.max(200,$(window).height() - 235); },
				
			columns: [ 
				   {  title:' ', columnMenu:false, sortable:false, filterable:false, template: "<a class=\"btn btn-primary waves-effect\" data-ui-sref=\"users.edit({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-edit\"></i></a> <a class=\"btn btn-info waves-effect\" data-ui-sref=\"users.show({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-search\"></i></a> <a class=\"btn btn-danger waves-effect\" href=\"\" ng-if=\"canDelete(  )\" ng-click=\"deleteUser( #:id # )\"><i class=\"zmdi zmdi-close\"></i></a>"  ,width:150  } ,
				 { field:'id',   title:'ID', width: 80  },
				 { field:'name',   title:'Nome'  , width:200  , template: "<div class=\"user-avatar-mini\" style=\"background-image:url('#:avatar_url#');\" ></div> <div class=\"user-name\">#: name #</div>" },
				 { field:'email',   title:'Email'  , width:250 },
				 
				 { field:'activated',   values:stateValues ,  title:'Activo' , width: 100 ,  template:'<a class="btn"  state-icon data-state="#: activated #"></a>' },
				 { field:'state', values:stateValues , title:'Estado' , width: 100 , template:'<a class="btn" state-icon data-state="#: state #"></a>'  },
				 { field:'created_at'  ,
						title: "Data registo", 
					format: "{0:dd/MM/yyyy HH:mm}",
					type:'date',
					 width:150,
					filterable: {
						// ui: "datetimepicker" 
						 ui: function(element) {
							element.kendoDateTimePicker({ format: "dd/MM/yyyy HH:mm"}); // initialize a Kendo UI DateTimePicker
						}
					}
				 },
				 { field:'last_login'  , 
						title: "Último login", 
					format: "{0:dd/MM/yyyy HH:mm}",
					type:'date',
					 width:150,
					filterable: {
						// ui: "datetimepicker" 
						 ui: function(element) {
							element.kendoDateTimePicker({ format: "dd/MM/yyyy HH:mm"}); // initialize a Kendo UI DateTimePicker
						}
					}
				 },
				 { field:'roles',   title:'Role'  , template:"<span ng-repeat=\"item in dataItem.roles\">{{ item.name }} &nbsp;</span>", width:250 }
				
				 
			]
	}); 
	 
		 
		// $scope.$watch('usersGrid', function () { 
			// var options = localStorage["usersGridOptions"]; 
			// if( options )
				// $scope.usersGrid.setOptions(  JSON.parse(options) );
		// });
		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {  				 
			if( $('div[kendo-grid]').length > 0 ) { 
				localStorage["usersGridOptions"] = kendo.stringify($scope.usersGrid.getOptions()); 
			} 
	   });
		
		 
		$scope.unlockColumns = function() {
			for(var i= 0; i< $scope.usersGrid.columns.length; i++) {
				// $scope.usersGrid.columns[i].locked = false;
				$scope.usersGrid.unlockColumn(i);
			}
			$scope.usersGrid.refresh();
		}
		$scope.canDelete = function(item){
			// console.log( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') ) 
			if( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') )
				return false;
			for(var i= 0; i< this.dataItem.roles.length; i++) {
				if( this.dataItem.roles[i].name == "owner" )
					return false;
				if( this.dataItem.roles[i].name == "super" )
					return false;
				
			}
			return true;
		}
		$scope.deleteUser = function(item_id) {
			 
			swal({
				title:"Tem a certeza?",
				text:"Tem a certeza que pretende apagar este o utilizador?" ,
				showCancelButton:true,
				confirmButtonText:"Apagar",
				cancelButtonText:"Cancelar",
				type:"warning",
				showLoaderOnConfirm: true,
				closeOnConfirm:false 
			}, function() {
				$http.delete("api/users/"+item_id).then(function(response) {
					$scope.usersGrid.dataSource.read();
					swal.close();
				}, function(response) {
					var message =  response.data.errors.length > 0 ? response.data.errors[0].detail : 'Ocorreu um erro com o seu pedido';
					growlService.notify({ message:message,type:'danger'});
					swal.close();
				});
				
			})
			 
		}
		
		
	}])
	
	
	.controller('userController', ['$state','$controller','$scope', '$stateParams','User','Role','Upload','growlService','currentUserService','editorFunctions', function($state,$controller,$scope,$stateParams,User,Role,Upload,growlService,currentUserService,editorFunctions) {
		
		
		
		var isProfile = $scope.isProfile = false;
		
		$scope.hasImageUpload = true;
		
		angular.extend(this, $controller('editorController', {$scope: $scope})); 
		editorFunctions.setSuccessUrl("users.index");  
		editorFunctions.setFormName("userForm"); 
		editorFunctions.setValidator("eventformvalidateapi"); 

		
		this.roles = [];  
		
		
		$scope.canEdit = {
			state:false,
			roles:false 
		}
		if( $scope.currentUser.hasRole(['owner','super']) ) {
		  $scope.canEdit.state = true;
		  $scope.canEdit.roles = true;
		  this.roles = Role.query({list:true});
		} 
		 
		if( $stateParams.profile === true ) { 
			$scope.user = User.get({id: $scope.currentUser.id} ); 
			isNew = $scope.isNew = false;
			isProfile = $scope.isProfile = true;
		} else if( $stateParams.id ) {
			$scope.user = User.get({id: $stateParams.id} ); 
		} else {
			$scope.user = new User({state:1, roleslist:[{id: 3, name: "user"}], avatar_url:"avatar/default.png" });  
		} 

   
		  
		  
		$scope.eventformvalidate = {
			rules: {
				'password_confirm': {
					equalTo:'#password'
				}
			},
			submitHandler: function(f) {
				if( $scope.picFile ) { 
					if( $scope.imageWasCropped ) {
						$scope.user.file = Upload.dataUrltoBlob($scope.images.croppedUrl);
						var rnd  = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
						$scope.user.file.name = rnd + "_"+$scope.picFile.name;
					} else
						$scope.user.file = $scope.picFile; 
				}
				
				$scope.user.uploadAndSave(editorFunctions.saveSuccess,editorFunctions.saveError, editorFunctions.setUploadProgress);			 
			}
		}; 

	}])
	
	
	