materialAdmin
	.controller('modalLoginCtrl', ['$rootScope','$scope','$auth','$state','$http','$modalInstance','currentUserService','growlService','authService', function($rootScope,$scope,$auth,$state,$http,$modalInstance,currentUserService,growlService,authService){
		$scope.eventformvalidate = {
			rules: {
				 
			},
			messages: {
			},
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				login();
				 
			}
		};
		  // 
		 
		 $rootScope.$on('event:auth-loginConfirmed', function() {
				 $modalInstance.close(); 
			});
			
		$scope.cancel = function () {
			$modalInstance.dismiss(); 
			authService.loginCancelled();
			authService.logout();
		 };
		
		var setFormErrors = function(responseErrors) {
			var errors = {}
			for( var x in  responseErrors) {
				errors[x] =   responseErrors[x]
			}
			$scope.eventformvalidateapi.getValidator().showErrors(errors);
		}
		 
		function login() {
			$scope.loginFailed = false;
			$scope.failedMessage = '';
			if( $scope.isPasswordRefresh ) {
				var url =   'api/authenticate/reconfirm' ;
				var credentials = {password: $scope.password };
			} else {
				var url =   'api/authenticate' ;
				var credentials = {password: $scope.password, email:$rootScope.currentUser.email};
			}
		
			$http.post(url,credentials).then(function(response) {
				// var headers = response.headers();
				if( response.data.token ) {
					var token = response.data.token;
					sessionStorage.setItem('satellizer_token',token);
					authService.loginConfirmed();
					$modalInstance.dismiss(); 
				}
			}).catch( function(error) {
				 
				$scope.loginFailed = true;
				$scope.failedMessage = error.data.error;
				
			});
							 
		};
	}])
	
    .controller('loginCtrl', ['$rootScope','$scope','$auth','$state','$http','currentUserService','growlService',function($rootScope,$scope,$auth,$state,$http,currentUserService,growlService){
        $rootScope.loginpage = true;
		 
        //Status
		var vm = this;
        vm.showLogin = 1;
        vm.loginOk = 0;
        vm.showRegister = 0;
        vm.showForgot = 0;
		vm.email = '';
		vm.password = '';
		vm.remmerber =1;
		$scope.eventformvalidate = { 
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				angular.element(f).find('input').trigger('input');
				 vm.login();
				 
			}
		};
	 
		//add docs here
		vm.login = function() {
			
			var credentials = {
				email: vm.email,
				password: vm.password
			} 
			$auth.login(credentials,{ignoreLoadingBar: true}).then(function() {

                // Return an $http request for the now authenticated
                // user so that we can flatten the promise chain
					return $http.get('api/authenticate/user');
				
				// Handle errors
				} ).then(function(response) {
					
					// Stringify the returned data to prepare it
					// to go into local storage
					var user = JSON.stringify(response.data.user);

					// Set the stringified user data into local storage
					// localStorage.setItem('user', user);

					// The user's authenticated state gets flipped to
					// true so we can now show parts of the UI that rely
					// on the user being logged in
					$rootScope.authenticated = true;

					// Putting the user's data on $rootScope allows
					// us to access it anywhere across the app
					currentUserService.setUser(response);
					
					
					// Everything worked out so we can now redirect to
					// the users state to view the data
					$rootScope.loginpage= false;
					vm.showLogin = 0;
					 growlService.notify({ message:'Sessão iniciada com sucesso',type:'success'});
					$state.go('home');
					 
					 
				}).catch(function(response) {
					// Handle errors here.
					console.log(response);
					vm.loginError = true;
					vm.loginErrorText = response.data.error;
					 growlService.notify({ message:response.data.error,type:'danger'});
				});
		}
		
		 
    }])
	
	 .controller('profileCtrl', ['$rootScope','$scope','$auth','$state','$http', 'growlService','authService', function($rootScope,$scope,$auth,$state,$http,  growlService,authService){  
		this.logout = function() {
			console.log('logout');
			authService.logout()
			
		}  
    }]) 
	
    
	.controller('recoverCtrl', ['$rootScope','$scope','$auth','$state','$http','currentUserService','growlService',function($rootScope,$scope,$auth,$state,$http,currentUserService,growlService){
        $rootScope.loginpage = true;
		 
        //Status
		var vm = this;
         vm.name="";
         vm.email="";
         
		$scope.recoverPassValidate = { 
			 
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				$('#email').trigger('input');
				 
				
				$http.post('api/password/email',{email: vm.email}).then( function(response) {
					 if( response.data.success ) {
							// growlService.notify({message:'A sua conta foi criada com sucesso', type:'success'});
							swal( {title:'Palavra-passe', text:'Enviámos-lhe um email com as instruções para repor a sua palavra-passe.', type:'success'}, function() {
								$state.go('auth.login');
							});
							
						} else {
							setFormErrors(response.data.errors);
						}
				 }, function(response) {
					 growlService.notify({message:'Houve um problema com o seu pedido', type:'danger'});
				 });
			}
		};
		var setFormErrors = function(responseErrors) {
			var errors = {}
			for( var x in  responseErrors) {
				errors[x] =   responseErrors[x]
			}
			$scope.registrationValidatorAPI.getValidator().showErrors(errors);
		}
		 
		 
    }])
	
    
	.controller('registerCtrl', ['$rootScope','$scope','$auth','$state','$http','currentUserService','growlService', function($rootScope,$scope,$auth,$state,$http,currentUserService,growlService){
        $rootScope.loginpage = true;
		 
        //Status
		var vm = this;
         vm.name="";
         vm.email="";
         vm.password="";
         vm.password_confirm=""; 
		$scope.registrationValidatorConfig = { 
			rules: {
				password: {
					required:true,
					minlength:6
				},
				password_confirmation: {
					equalTo : '#password'
				}
			},
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				$('#email').trigger('input');
				$('#password').trigger('input');
				$('#password_confirm').trigger('input');
				var data = {
					name: vm.name,
					email: vm.email,
					password: vm.password,
					password_confirm: vm.password_confirm
				}; 
				 $http.post('api/register',data).then( function(response) {
					 if( response.data.success ) {
							// growlService.notify({message:'A sua conta foi criada com sucesso', type:'success'});
							swal( {title:'A sua conta foi criada com sucesso', text:'Enviámos-lhe um email com as instruções para ativar a sua conta.', type:'success'}, function() {
								$state.go('auth.login');
							});
							
						} else {
							setFormErrors(response.data.errors);
						}
				 }, function(response) {
					 growlService.notify({message:'Houve um problema com o seu pedido', type:'danger'});
				 });
			}
		};
		var setFormErrors = function(responseErrors) {
			var errors = {}
			for( var x in  responseErrors) {
				errors[x] =   responseErrors[x]
			}
			$scope.registrationValidatorAPI.getValidator().showErrors(errors);
		}
		 
		 
    }])
	
	.controller('resetPassCtrl', ['$rootScope','$scope','$auth','$state','$http','$stateParams','growlService' ,function($rootScope,$scope,$auth,$state,$http,$stateParams,growlService){
        $rootScope.loginpage = true;
		 
        //Status
		var vm = this;
        vm.password="";
        vm.password_confirmation="";
		vm.token = $stateParams.token;
		$scope.resetPassValidate = { 
			rules: {
				password: {
					required:true,
					minlength:6
				},
				password_confirmation: {
					equalTo : '#password'
				}
			},
			 onfocusout: false,
			validateOnInit: false,
			submitHandler: function(f) {
				$('#password_confirmation').trigger('input');
				$('#email').trigger('input');
				$('#password').trigger('input');
				 
				 $http.post('api/password/resetpass',{email:vm.email,password: vm.password, password_confirmation: vm.password_confirmation, token:vm.token }).then( function(response) {
					if( response.data.success ) {
						swal({title:'Nova palavra-passe',text:'A sua palavra-passe foi alterada com sucesso', type:'success'},
							function() {
								$state.go('auth.login');
							}
						);
					} else {
						setFormErrors(response.data.errors);
					}
				 }, function(response) {
					growlService.notify({message:'Houve um problema com o seu pedido', type:'danger'});
				 });
			}
		};
		var setFormErrors = function(responseErrors) {
			var errors = {}
			for( var x in  responseErrors) {
				errors[x] =   responseErrors[x]
			}
			$scope.resetPassValidationAPI.getValidator().showErrors(errors);
		}
		 
		 
    }])
	