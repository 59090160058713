materialAdmin
	.run(['$rootScope','$state','$location','$http','$timeout','$auth','$interval','$modal','amMoment','dsTransport','currentUserService','authService','growlService','cfpLoadingBar',function ($rootScope, $state,$location, $http, $timeout,$auth,$interval,$modal,amMoment, dsTransport,currentUserService,authService,growlService,cfpLoadingBar) {
			$rootScope.loginpage = false;
			$rootScope.refreshingToken = false;
			 
			  amMoment.changeLocale('pt');
			 
			 //Checks if user is authenticated, if not, redirects to login page.
			$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {  				 
						// console.log(toParams);
				   if( ! $auth.isAuthenticated() ) {
						
						if( toParams.noLogin !== true ) { 
							event.preventDefault();
							console.log("needs login");
							$state.go('auth.login');
							
							return; 
						}
							 
				   }
		   });
		   $rootScope.isPasswordRefresh = false;
		   $rootScope.$on('ocLazyLoad.fileLoaded', function() {
				cfpLoadingBar.complete();
			});
		   $rootScope.$on('event:auth-loginRequired', function() {
				$rootScope.isPasswordRefresh = false;
				 loginModal();
				  
			});
		   $rootScope.$on('event:auth-passwordRequired', function() {
				$rootScope.isPasswordRefresh = true;
				 loginModal();
				 
			});
		   $rootScope.$on('event:auth-refresh', function() {
				 console.log('refreshing');
				  
				 authService.refresh();
				 
			});
		  
		   
		   
		   $rootScope.animationsEnabled = true;
			
			function loginModal( ) {
				 if( ! $rootScope.currentUser ) { 
					$state.go('auth.login');
					$auth.logout();
					event.preventDefault();
					return;
				}
				var loginModal = $modal.open({
					animation: true,
					templateUrl: 'views/auth/lockout.html',
					controller: 'modalLoginCtrl',
					scope:$rootScope,
					 
					backdrop: 'static',
					windowClass:'lockScreen',
					keyboard: false ,
					resolve: {
						content: function () {
							return $rootScope.modalContent;
						}
					}
				
				});
			}
		   
		   if( $auth.isAuthenticated() )
				currentUserService.getUser();
		   
			$rootScope.dsTransport = dsTransport;
			  
		  
		 
	}])
    
	 
   
	// =========================================================================
    // Base controller for common functions
    // =========================================================================

    .controller('materialadminCtrl', ['$timeout', '$state', 'growlService',function($timeout, $state, growlService){
         
        
        
        // Detact Mobile Browser
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
           angular.element('html').addClass('ismobile');
        }

        // By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
        this.sidebarToggle = {
            left: false,
            right: false
        }

        // By default template has a boxed layout
        this.layoutType = localStorage.getItem('ma-layout-status');
        
        // For Mainmenu Active Class
        this.$state = $state;    
        
        //Close sidebar on click
        this.sidebarStat = function(event) {
            if (!angular.element(event.target).parent().hasClass('active')) {
                this.sidebarToggle.left = false;
            }
        }
         
    }])


    // =========================================================================
    // Header
    // =========================================================================
    .controller('headerCtrl', ['$scope','$state','$interval','$http', 'growlService','messagesService','notificationsService','socket','$timeout',function($scope,$state,$interval,$http, growlService,messagesService,notificationsService,socket,$timeout){
		var that = this;
		$scope.totalUnread = 0;
		$scope.unreadNotifications = 0;
		$scope.notificationsList = [];
		
		that.messagesLoaded = false;
        this.messageResult = [];
		
		$scope.toggled = function(open) {
			$scope.status.isopen = open;
			if( open ) {
				that.getMessages();
			}
		};
		$scope.toggledNotifications = function(open) {
			$scope.status.notificationsOpen = open;
			if( open ) {
				getNotifications();
			}
		};
		var getNotifications = function() {
			that.getNotifications();
			$timeout(function() {
				if( $scope.status.notificationsOpen ) {
					notificationsService.markRead($scope.notificationsList);
				}
			},2000);
		}
		 $scope.status = {
			isopen: false,
			notificationsOpen:false
		  };
				
		$scope.notificationClick = function($event) {
			 var url = angular.element($event.currentTarget).data('url');
			 var params = angular.element($event.currentTarget).data('params');
			
			 $state.go(url,params);
		}
		this.getMessages = function() { 
			that.messagesLoaded = true;
			messagesService.getNotificationList(that,'messageResult'); 
		}
        this.countUnreadMessages = function(open) {
			 
			messagesService.getUnreadCount().then(function(response) {
				if( $scope.status.isopen && $scope.totalUnread != response.data)
					that.getMessages();
				
				$scope.totalUnread = response.data;
				console.log($scope);
			});
		}
		
		
        this.countUnreadNotifications = function(open) {
			// $http.get('api/notifications/count').then(function(response) {
				// $scope.unreadNotifications = response.data;
			// });
			notificationsService.getCount($scope,'unreadNotifications');
			// messagesService.getUnreadCount().then(function(response) {
				// if( $scope.status.isopen && $scope.totalUnread != response.data)
					// that.getMessages(); 
				
				// $scope.totalUnread = response.data;
				// console.log($scope);
			// });
		}
		
		this.getNotifications = function() {
			notificationsService.getNotificationList($scope,'notificationsList');
		}
		
		
		this.messageClicked = function(message) {
			$state.go('messages.view',{id:message.message.thread_id} );
		}
		
		that.countUnreadNotifications();
		that.countUnreadMessages();
		 
		socket.on('private-channel:newMessage', function(data) {
			console.log('new message on header');
			$scope.$apply(function() {
				  $scope.totalUnread = data.unread;
			}); 
		}.bind(this));
		
		socket.on('private-channel:notification', function(data) {
			console.log('new notification');
			$scope.$apply(function() {
				  $scope.unreadNotifications = data.unread;
			}); 
		}.bind(this));
		
		socket.on('private-channel:markedMessageRead', function(data) { 
			$scope.$apply(function() {
				  $scope.totalUnread = data.unread;
			});
				
		}.bind(this));
		$scope.$on("$destroy", function() {
			socket.removeListener('private-channel:newMessage');
		});
		
        //Clear Notification
        this.clearNotification = function($event) {
            $event.preventDefault();
            
            // var x = angular.element($event.target).closest('.listview');
            // var y = x.find('.lv-item');
            // var z = y.size();
            
            // angular.element($event.target).parent().fadeOut();
            
            // x.find('.list-group').prepend('<i class="grid-loading hide-it"></i>');
            // x.find('.grid-loading').fadeIn(1500);
            // var w = 0;
            
            // y.each(function(){
                // var z = $(this);
                // $timeout(function(){
                    // z.addClass('animated fadeOutRightBig').delay(1000).queue(function(){
                        // z.remove();
                    // });
                // }, w+=150);
            // })
            
            // $timeout(function(){
                // angular.element('#notifications').addClass('empty');
            // }, (z*150)+200);
			notificationsService.dismissNotifications($scope.notificationsList);
			getNotifications();
        }
        
        // Clear Local Storage
        this.clearLocalStorage = function() {
            
            //Get confirmation, if confirmed clear the localStorage
            swal({   
                title: "Are you sure?",   
                text: "All your saved localStorage values will be removed",   
                type: "warning",   
                showCancelButton: true,   
                confirmButtonColor: "#F44336",   
                confirmButtonText: "Yes, delete it!",   
                closeOnConfirm: false 
            }, function(){
                localStorage.clear();
                swal("Done!", "localStorage is cleared", "success"); 
            });
            
        }
        
        //Fullscreen View
        this.fullScreen = function() {
            //Launch
            function launchIntoFullscreen(element) {
                if(element.requestFullscreen) {
                    element.requestFullscreen();
                } else if(element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if(element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if(element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
            }

            //Exit
            function exitFullscreen() {
                if(document.exitFullscreen) {
                    document.exitFullscreen();
                } else if(document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if(document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }

            if (exitFullscreen()) {
                launchIntoFullscreen(document.documentElement);
            }
            else {
                launchIntoFullscreen(document.documentElement);
            }
        }
    
    }])
	
	// Editor Base Controller
	.controller('editorController', ['$state','$scope','$stateParams','Upload','editorFunctions', function($state,$scope,$stateParams,Upload,editorFunctions) {
		$scope.isNew =  $stateParams.id ? false:true;
		editorFunctions.setScope($scope);  
		if( $scope.hasImageUpload ) {
			$scope.uploadProgress = 0; 
			$scope.imageWasCropped = false;
			$scope.images  = {croppedUrl:'',tempCroppedUrl:''}; 
			
			editorFunctions.setCropper();
		}
		
		 
		$scope.errors = {};  
		$scope.$on('$stateChangeStart', editorFunctions.checkDirty);
	}])
 
	
	//Messages
	.controller('messagesController', ['$scope','$interval', 'growlService','messagesService','socket','$auth', function($scope,$interval, growlService,messagesService,socket,$auth){
		
		$scope.refreshConversations = function(opts) { 
			var disableLoadingBar =   opts.disableLoadingBar || false;
			 messagesService.getConversations(disableLoadingBar).then(function(response) {
				$scope.conversations  = response.data;
			});
			 
			  
		}
		socket.on('private-channel:newMessage', function(data) {
			console.log('new message on messages page');
			$scope.$apply(function() {
				  $scope.totalUnread = data.unread;
				  $scope.refreshConversations({disableLoadingBar:false});
			}); 
		}.bind(this))
		 
		$scope.refreshConversations({disableLoadingBar:false});
		// var messagesTimer = $interval($scope.refreshConversations, 5 * 1000,0, true, {disableLoadingBar:true});
		
		$scope.$on("$destroy", function() {
			socket.removeListener('private-channel:newMessage');
		});
		
		 var token =  $auth.getToken();
		  
		 
		
	 
		
	}])
	//Messages
	.controller('messageController', ['$scope','$interval','$stateParams', 'growlService','messagesService','socket',function($scope,$interval,$stateParams, growlService,messagesService,socket){
		var that =this;
		$scope.refreshConversation = function(opts) { 
			var disableLoadingBar =   opts.disableLoadingBar || false;
			 messagesService.getConversation($stateParams.id,disableLoadingBar).then(function(response) {
				 $scope.conversation = response.data; 
				 var last = $scope.conversation.messages.length -1;
				 var hasUnread = false;
				 var lastSender = 0;
				 for(var i=0; i<=last;i++) {
					if( lastSender != $scope.conversation.messages[i].sender_id ) {
						$scope.conversation.messages[i].same_sender_as_prev = false;
						lastSender = $scope.conversation.messages[i].sender_id;
					} else {
						$scope.conversation.messages[i].same_sender_as_prev = true;
					}
					 
					if($scope.conversation.id, $scope.conversation.messages[i].read == false)
						hasUnread =true;
				 }
				 if( hasUnread )
					messagesService.markConversationRead($scope.conversation.id, $scope.conversation.messages[last].id);
			 });
		}
		
		$scope.refreshConversation({disableLoadingBar:false});
		// var messagesTimer = $interval($scope.refreshConversation, 5 * 1000,0, true, {disableLoadingBar:true});
		$scope.replycontents = '';
		
		$scope.eventformvalidate = { 
			  
			submitHandler: function(f) {
				 
					messagesService.sendMessage($scope.conversation.id,$scope.replycontents ).then(function(response) {
						$scope.replycontents = ''; 
						// $scope.refreshConversation();
						$scope.conversation = response.data.thread; 
					})
				
				 				  
			}
		};
		socket.on('private-channel:newMessage', function(data) {
			console.log('new message on message page');
			$scope.$apply(function() {
				  $scope.totalUnread = data.unread;
				  if( data.message.thread_id == $scope.conversation.id )
					$scope.refreshConversation({disableLoadingBar:false});
			}); 
		}.bind(this))
		
		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {  	
			  // $interval.cancel(messagesTimer);
			  socket.removeListener('private-channel:newMessage');
		});
		 
	}])
	
	.controller('createMessageController', ['$scope','$state','$http','$interval','$stateParams', 'growlService','messagesService',function($scope,$state,$http,$interval,$stateParams, growlService,messagesService){
		var that =this;
		
		$scope.message = {
			content:'',
			subject:'',
			to:''
		}
		
		$scope.selectOptions = {
            optionLabel: {
				id:'',
				name:"Selecione o destinatário.."
			},
			
            optionLabelTemplate: "<span class=\"user-name\">Selecione o destinatário</span>",   
            dataTextField: "name",
            dataValueField: "id",
			filter: "contains",
			
			  template: '<span class="user-avatar-mini" style="background-image: url(\'#:data.avatar_url#\')"></span>' +
                                  '<span class="user-name">#: name #</span>',
            
              valueTemplate: '<span class="selected-value"><span class="user-avatar-mini" style="background-image: url(\'#:data.avatar_url#\')"></span>' +
                                  '<span class="user-name">#: name #</span></span>',
                                 
            valuePrimitive: true,
            autoBind: false,
			 virtual: {
                itemHeight: 38,
                valueMapper: function(options) {
						 
						$http.get('api/messages/valueMapper',{params: convertValues(options.value) }).then(function(response) {
							options.success(response.data);
						});
					  
                     
                }
            },
			 dataSource: {
                 type: "json", 
                transport: {  
                    read: function (e) {
					  $scope.dsTransport.src('api/messages/recipients',e,'');
					}
                },
                schema: {
					total:'total',
					data:'data',
                    model: {
                        fields: {
                            id: { type: "number" }, 
                            name: { type: "string" } 
                        }
                    }
                },
                pageSize: 21,
                serverPaging: true,
                serverFiltering: true
            }
            
        };
		function convertValues(value) {
			var data = {};

			value = $.isArray(value) ? value : [value];

			for (var idx = 0; idx < value.length; idx++) {
				data["values[" + idx + "]"] = value[idx];
			}

			return data;
		}
		$scope.eventformvalidate = { 
			ignore:[],
			submitHandler: function(f) { 
				messagesService.sendNewMessage($scope.message.to, $scope.message.subject, $scope.message.content).then(function(response) {
					if( response.data.success ) {
						$state.go('messages.view',{id:response.data.thread.id})
					} else {
						
					}
				});
			}
		};
		
		 
		 
	}])
