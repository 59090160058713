var materialAdmin = angular.module('materialAdmin', [
    'ngAnimate',
    'ngResource',
    'ui.router',
    'ui.bootstrap',
    'angular-loading-bar',
    'oc.lazyLoad',
    'nouislider',
    'ngFileUpload',
    'ngImgCrop',
	'angular-jquery-validate' ,
	// 'localytics.directives',
	'checklist-model',
	'angularMoment',
	'satellizer',
	'kendo.directives'
	// 'blueimp.fileupload'
    // 'ngTable',
]) 
	.directive(
            "bnLogDomCreation",
            function() {
                // I bind the UI to the $scope.
                function link( $scope, element, attributes ) {
                    console.log(
                        attributes.bnLogDomCreation,
                        $scope.$index
                    );
                }
                // Return the directive configuration.
                return({
                    link: link
                });
            }
        )
.filter('strLimit', ['$filter', function($filter) {
	return function(input, limit) {
		if (! input) return;
		if (input.length <= limit) {
			return input;
		}
		return $filter('limitTo')(input, limit) + '...';
	};
	}])
		.directive('stateIcon', [ '$rootScope', '$state', function($rootScope, $state) {

		return function(scope, elem, attr) {
			var state = parseInt(attr.state) || 0;
			var icon = '';
			var color = '';
			var tooltip = '-';
			switch(state) {
				case 0: 
					color = 'red';
					icon = 'close';
					tooltip = 'Inactivo'
					break;
				case 1:
					color = 'green';
					icon = 'check';
					tooltip = 'Activo'
					break;
				case -1:
					color = 'red';
					icon = 'ban';
					tooltip = 'Bloqueado'
					break;
			}
			  elem.addClass('bgm-'+color).attr('tooltip',tooltip).append('<i class="zmdi zmdi-'+icon+'"></i>');
			 
			
		};

  }]);
 