materialAdmin
	.factory('Volunteer', ['Model', function(Model) {
		return  new Model('api/volunteers','volunteer'); 
	}])
	
	
	.controller('volunteersController', ['$http','$scope','growlService','dsGridOptions', function($http,$scope,growlService, dsGridOptions) {
		var stateValues = [  {value:0, text:'Inactivo'}, {value:-1, text:'Activo'}]
		
		
		var schema = {
			data: "data", // records are returned in the "data" field of the response
			total: "total", // total number of records is in the "total" field of the response
			model:{ 
				id: "id",
				fields: { 
					birth_date: {type:'date'} ,
					created_at: {type:'date'} 
				} 
			}
		}
		var dataSource = dsGridOptions.getDataSource("volunteersGridOptions",'api/volunteers', {schema:schema });
		
		$scope.gridOptions = dsGridOptions.getOptions("volunteersGridOptions",{
			 dataSource:dataSource ,
			
			height: function() { return Math.max(200,$(window).height() - 235); },
				
			columns: [ 
				   {  title:' ', columnMenu:false, sortable:false, filterable:false, template: "<a class=\"btn btn-primary waves-effect\" data-ui-sref=\"volunteers.edit({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-edit\"></i></a> <a class=\"btn btn-info waves-effect\" data-ui-sref=\"volunteers.show({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-search\"></i></a> <a class=\"btn btn-danger waves-effect\" href=\"\" ng-if=\"canDelete(  )\" ng-click=\"deleteItem( #:id # )\"><i class=\"zmdi zmdi-close\"></i></a>"  ,width:150  } ,
				 { field:'id',   title:'ID', width: 80  },
				 { field:'name',   title:'Nome'  , width:200  , template: "<div class=\"user-avatar-mini\" style=\"background-image:url('#:avatar_url#');\" ></div> <div class=\"user-name\">#: name #</div>" },
				 { field:'birth_date',   title:'Data de Nascimento'  , type:'date',
					 width:150,
					 format: "{0:dd/MM/yyyy}",
					filterable: {
						// ui: "datetimepicker" 
						 ui: function(element) {
							element.kendoDateTimePicker({ format: "dd/MM/yyyy"}); // initialize a Kendo UI DateTimePicker
						}
					}
				},
				 
				  
				 
				 { field:'state',   values:stateValues ,  title:'Estado' , width: 100 ,  template:'<a class="btn"  state-icon data-state="#: state #"></a>' },
				 { field:'created_at'  ,
						title: "Data registo", 
					format: "{0:dd/MM/yyyy HH:mm}",
					type:'date',
					 width:150,
					filterable: {
						// ui: "datetimepicker" 
						 ui: function(element) {
							element.kendoDateTimePicker({ format: "dd/MM/yyyy HH:mm"}); // initialize a Kendo UI DateTimePicker
						}
					}
				 } 
				
				 
			]
	}); 
	 
	 
		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {  				 
			if( $('div[kendo-grid]').length > 0 ) { 
				localStorage["volunteersGridOptions"] = kendo.stringify($scope.volunteersGrid.getOptions()); 
			} 
	   });
		
		 
		$scope.unlockColumns = function() {
			for(var i= 0; i< $scope.volunteersGrid.columns.length; i++) {
				// $scope.usersGrid.columns[i].locked = false;
				$scope.volunteersGrid.unlockColumn(i);
			}
			$scope.volunteersGrid.refresh();
		}
		$scope.canDelete = function(item){
			// console.log( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') ) 
			if( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') )
				return false;
			 
			return true;
		}
		$scope.deleteItem = function(item_id) {
			 
			swal({
				title:"Tem a certeza?",
				text:"Tem a certeza que pretende apagar esta unidade?" ,
				showCancelButton:true,
				confirmButtonText:"Apagar",
				cancelButtonText:"Cancelar",
				type:"warning",
				showLoaderOnConfirm: true,
				closeOnConfirm:false 
			}, function() {
				$http.delete("api/volunteers/"+item_id).then(function(response) {
					$scope.volunteersGrid.dataSource.read();
					swal.close();
				}, function(response) {
					var message =  response.data.errors.length > 0 ? response.data.errors[0].detail : 'Ocorreu um erro com o seu pedido';
					growlService.notify({ message:message,type:'danger'});
					swal.close();
				});
				
			})
			 
		}
		
		
	}])
	  .controller('ModalInstanceCtrl', function ($scope, $modalInstance, content) {

          $scope.modalContent = content;

          $scope.ok = function () {
            $modalInstance.close();
          };

          $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
          };
    })
	
	.controller('volunteerController', ['$state','$controller','$scope', '$stateParams','Volunteer','Upload','growlService','currentUserService','editorFunctions','commonDropDowns','$modal', function($state,$controller,$scope,$stateParams,Volunteer,Upload,growlService,currentUserService,editorFunctions,commonDropDowns,$modal) { 
	
		var isProfile = $scope.isProfile = false;
		
		$scope.hasImageUpload = true;
		
		angular.extend(this, $controller('editorController', {$scope: $scope})); 
		editorFunctions.setSuccessUrl("volunteers.index");  
		editorFunctions.setFormName("volunteersForm"); 
		editorFunctions.setValidator("eventformvalidateapi"); 
		$scope.modalSelected = function(o) {
				for(var i=0; i<o.items.length;i++) {
					var found = false;
					for(var c=0; c<$scope.volunteer.projects.length; c++) {
						if( $scope.volunteer.projects[c].id == o.items[i].id)
							found =true;
					}
					if(!found)
						$scope.volunteer.projects.push(o.items[i]);
					
				}
					
				 
				modalInstance.close();
		 }
		 var modalInstance;
		//Modals
		function modalInstances(animation, size, backdrop, keyboard) {
            modalInstance = $modal.open({
                animation: animation,
               templateUrl: 'views/projects/select.html',
                controller: 'ModalInstanceCtrl',
                size: 'lg',
				scope: $scope,
                backdrop: backdrop,
                keyboard: keyboard,
					resolve: {
						content: function () {
							return $scope.modalContent;
						}
					}
            
            });
        }
		
		$scope.popupProjects = function (size) {

			 modalInstances(true, size, true, true)
		  };
		//Adicionar datepicker:
		/*
		adicionar css no app.less
		adicionar script
		adicionar $scope vars e functions
		adicionar ng-click=\"$event.stopPropagation()\ ao template popup
		*/
		$scope.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1
        };
		$scope.today = function() {
            $scope.dt = new Date();
        };
        $scope.today();
        $scope.toggleMin = function() {
            $scope.minDate = $scope.minDate ? null : new Date();
        };
        $scope.toggleMin();
		
		  $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
		 $scope.open = function($event, opened) {
            $event.preventDefault();
            $event.stopPropagation();
			console.log($scope);
            $scope[opened] = true;
        }; 
		$scope.$watch('opened', function(newValue, oldValue) {
		  console.info('changed open' + newValue);
		});
		
		//fim funcoes datepicker
		
		$scope.canEdit = {
			state:true 
		}
		
		if( $scope.currentUser.hasRole(['owner','super']) ) {
		  $scope.canEdit.state = true; 
		} 
		 
		if( $stateParams.id ) {
			$scope.volunteer = Volunteer.get({id: $stateParams.id} ); 
		} else {
			$scope.volunteer = new Volunteer({state:1, avatar_url:"avatar/default.png" });  
		} 

		
		$scope.districtsDropDown = commonDropDowns.districts();
		$scope.countiesDropDown = commonDropDowns.counties();
		  
		  
		$scope.eventformvalidate = {
			ignore:[],
			rules: {
				 
			},
			submitHandler: function(f) {
				if( $scope.picFile ) { 
					if( $scope.imageWasCropped ) {
						$scope.volunteer.file = Upload.dataUrltoBlob($scope.images.croppedUrl);
						var rnd  = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
						$scope.volunteer.file.name = rnd + "_"+$scope.picFile.name;
					} else
						$scope.volunteer.file = $scope.picFile; 
				}
				
				$scope.volunteer.uploadAndSave(editorFunctions.saveSuccess,editorFunctions.saveError, editorFunctions.setUploadProgress);			 
			}
		}; 

	}])
	
	