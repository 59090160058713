materialAdmin
	.factory('Service', ['Model', function(Model) {
		return  new Model('api/services','service'); 
	}])
	
	
	.controller('servicesController', ['$http','$scope','growlService','dsGridOptions', function($http,$scope,growlService, dsGridOptions) {
		var stateValues = [  {value:0, text:'Inactivo'}, {value:-1, text:'Activo'}]
		
		
		
		var dataSource = dsGridOptions.getDataSource("servicesGridOptions",'api/services');
		
		$scope.gridOptions = dsGridOptions.getOptions("servicesGridOptions",{
			 dataSource:dataSource , 
			height: function() { return Math.max(200,$(window).height() - 235); },
				
			columns: [ 
				   {  title:' ', columnMenu:false, sortable:false, filterable:false, template: "<a class=\"btn btn-primary waves-effect\" data-ui-sref=\"services.edit({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-edit\"></i></a> <a class=\"btn btn-info waves-effect\" data-ui-sref=\"services.show({id: {{dataItem.id}} } )\"><i class=\"zmdi zmdi-search\"></i></a> <a class=\"btn btn-danger waves-effect\" href=\"\" ng-if=\"canDelete(  )\" ng-click=\"deleteItem( #:id # )\"><i class=\"zmdi zmdi-close\"></i></a>"  ,width:150  } ,
				 { field:'id',   title:'ID', width: 80  },
				 { field:'name',   title:'Nome'  , width:200  , template: "<div class=\"user-avatar-mini\" style=\"background-image:url('#:logo_url#');\" ></div> <div class=\"user-name\">#: name #</div>" },
				 { field:'unit.name',   title:'Instituição'  , width:200  , template: "<div class=\"user-avatar-mini\" style=\"background-image:url('#:unit.logo_url#');\" ></div> <div class=\"user-name\">#: unit.name #</div>" },
				 { field:'unit.institution.name',   title:'Instituição'  , width:200  , template: "<div class=\"user-avatar-mini\" style=\"background-image:url('#:unit.institution.logo_url#');\" ></div> <div class=\"user-name\">#: unit.institution.name #</div>" },
				  
				 
				 { field:'state',   values:stateValues ,  title:'Estado' , width: 100 ,  template:'<a class="btn"  state-icon data-state="#: state #"></a>' },
				 { field:'created_at'  ,
						title: "Data registo", 
					format: "{0:dd/MM/yyyy HH:mm}",
					type:'date',
					 width:150,
					filterable: {
						// ui: "datetimepicker" 
						 ui: function(element) {
							element.kendoDateTimePicker({ format: "dd/MM/yyyy HH:mm"}); // initialize a Kendo UI DateTimePicker
						}
					}
				 } 
				
				 
			]
	}); 
	 
	 
		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {  				 
			if( $('div[kendo-grid]').length > 0 ) { 
				localStorage["servicesGridOptions"] = kendo.stringify($scope.servicesGrid.getOptions()); 
			} 
	   });
		
		 
		$scope.unlockColumns = function() {
			for(var i= 0; i< $scope.servicesGrid.columns.length; i++) {
				// $scope.usersGrid.columns[i].locked = false;
				$scope.servicesGrid.unlockColumn(i);
			}
			$scope.servicesGrid.refresh();
		}
		$scope.canDelete = function(item){
			// console.log( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') ) 
			if( ! $scope.currentUser.hasRole('owner')  && ! $scope.currentUser.hasRole('super') )
				return false;
			 
			return true;
		}
		$scope.deleteItem = function(item_id) {
			 
			swal({
				title:"Tem a certeza?",
				text:"Tem a certeza que pretende apagar esta unidade?" ,
				showCancelButton:true,
				confirmButtonText:"Apagar",
				cancelButtonText:"Cancelar",
				type:"warning",
				showLoaderOnConfirm: true,
				closeOnConfirm:false 
			}, function() {
				$http.delete("api/services/"+item_id).then(function(response) {
					$scope.servicesGrid.dataSource.read();
					swal.close();
				}, function(response) {
					var message =  response.data.errors.length > 0 ? response.data.errors[0].detail : 'Ocorreu um erro com o seu pedido';
					growlService.notify({ message:message,type:'danger'});
					swal.close();
				});
				
			})
			 
		}
		
		
	}])
	
	
	.controller('serviceController', ['$state','$controller','$scope', '$stateParams','Service','Upload','growlService','currentUserService','editorFunctions','commonDropDowns', function($state,$controller,$scope,$stateParams,Service,Upload,growlService,currentUserService,editorFunctions,commonDropDowns) { 
	
		var isProfile = $scope.isProfile = false;
		
		$scope.hasImageUpload = true;
		
		angular.extend(this, $controller('editorController', {$scope: $scope})); 
		editorFunctions.setSuccessUrl("services.index");  
		editorFunctions.setFormName("servicesForm"); 
		editorFunctions.setValidator("eventformvalidateapi"); 

		 
		
		$scope.canEdit = {
			state:true 
		}
		if( $scope.currentUser.hasRole(['owner','super']) ) {
		  $scope.canEdit.state = true; 
		} 
		 
		if( $stateParams.id ) {
			$scope.service = Service.get({id: $stateParams.id} ); 
		} else {
			$scope.service = new Service({state:1, logo_url:"avatar/default.png" });  
		} 

		
		$scope.institutionsDropDown = commonDropDowns.institutions();
		$scope.unitsDropDown = commonDropDowns.units();
		  
		  
		$scope.eventformvalidate = {
			ignore:[],
			rules: {
				'institution_id': {
					required:true
				},
				'unit_id':'required'
			},
			submitHandler: function(f) {
				if( $scope.picFile ) { 
					if( $scope.imageWasCropped ) {
						$scope.service.file = Upload.dataUrltoBlob($scope.images.croppedUrl);
						var rnd  = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
						$scope.service.file.name = rnd + "_"+$scope.picFile.name;
					} else
						$scope.service.file = $scope.picFile; 
				}
				
				$scope.service.uploadAndSave(editorFunctions.saveSuccess,editorFunctions.saveError, editorFunctions.setUploadProgress);			 
			}
		}; 

	}])
	
	