materialAdmin
	.config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
		cfpLoadingBarProvider.includeBar = false;
	  }])
	.config(['$ocLazyLoadProvider',function($ocLazyLoadProvider) {
		$ocLazyLoadProvider.config({
		  events: true,
		  debug:false
		});
	}])
	 .config(['$jqueryValidateProvider',function ($jqueryValidateProvider) {
			
			//jQuery Validation
			$jqueryValidateProvider.setDefaults({
			errorElement : 'small',
			errorClass : 'help-block',
			onfocusout: false, 
			validateOnInit: false,
		   errorPlacement: function (error, element) {
			// console.log(error);
				var p = $(element).parents('.form-group:first');
				p.append(error);
		   },
		   highlight: function(element) {
				 $(element).parents('.form-group:first').addClass("has-error");
			},
			unhighlight: function(element) {
				 $(element).parents('.form-group:first').removeClass("has-error");
			}
		   
		  });

		  $jqueryValidateProvider.addMethod("time24", function (value, element) {
		   return /^([01]?[0-9]|2[0-3])(:[0-5][0-9]){2}$/.test(value);
		  }, "Invalid time format.");

		  $jqueryValidateProvider.addMethod("time24WithoutSeconds", function (value, element) {
		   return /^([0-1]\d|2[0-3]):([0-5]\d)/.test(value);
		  }, "Invalid time format.");
		}])
	.config(['$stateProvider', '$urlRouterProvider','$authProvider','$httpProvider', '$provide',function($stateProvider, $urlRouterProvider,$authProvider,$httpProvider, $provide) {
		function requestPasswordFromTimetoTme($q,$injector) {
				return {
                   'responseError': function(response) {
						if (!rejection.config.ignoreAuthModule) {
						switch (rejection.status) {
						  case 401:
							var deferred = $q.defer();
							httpBuffer.append(rejection.config, deferred);
							$rootScope.$broadcast('event:auth-loginRequired', rejection);
							return deferred.promise;
						  case 403:
							$rootScope.$broadcast('event:auth-forbidden', rejection);
							break;
						}
					  }
					  if (response.status === 480) {
						// should retry
						var o = swal({   title: "Are you sure?",   
								 text: "You will not be able to recover this imaginary file!",   
								 type: "warning",   showCancelButton: true,   
								 confirmButtonColor: "#DD6B55",   
								 confirmButtonText: "Yes, delete it!",   
								 closeOnConfirm: false 
						}, function(){   
								var $http = $injector.get('$http');
								return $http(response.config);
						}, function() {
							return $q.reject(response);
						});
						console.log(o);
					  }
					  // give up
					  return $q.reject(response);
					}
				}
		}
		
		function redirectWhenLoggedOut($q, $injector) {

                return {

                    responseError: function(rejection) {
						 
                        // Need to use $injector.get to bring in $state or else we get
                        // a circular dependency error
                        var $state = $injector.get('$state');

                        // Instead of checking for a status code of 400 which might be used
                        // for other reasons in Laravel, we check for the specific rejection
                        // reasons to tell us if we need to redirect to the login state
                        var rejectionReasons = ['token_not_provided', 'token_expired', 'token_absent', 'token_invalid'];

                        // Loop through each rejection reason and redirect to the login
                        // state if one is encountered
                        angular.forEach(rejectionReasons, function(value, key) {

                            if(rejection.data.error === value) {

                                // If we get a rejection corresponding to one of the reasons
                                // in our array, we know we need to authenticate the user so 
                                // we can remove the current user from local storage
                                localStorage.removeItem('user');

                                // Send the user to the auth state so they can login
                                $state.go('login');
                            }
                        });

                        return $q.reject(rejection);
                    }
                }
            }

		// Setup for the $httpInterceptor
		// $provide.factory('redirectWhenLoggedOut', redirectWhenLoggedOut);
		 

		 // Push the new factory onto the $http interceptor array
         // $httpProvider.interceptors.push('redirectWhenLoggedOut'); 
			
		$authProvider.storageType  = 'sessionStorage'; 
		$authProvider.baseUrl = apiURL ;
		$authProvider.loginUrl = apiURL+ 'api/authenticate';
		
	}])
	.config(['$httpProvider', function($httpProvider) {
		$httpProvider.interceptors.push(['$rootScope', '$q', 'httpBuffer', function($rootScope, $q, httpBuffer) {
		  return {
			response: function(response) {
				var headers = response.headers();
				if( typeof response.config.isTokenRefresh == "undefined" || response.config.isTokenRefresh == false ) {
					if( typeof headers['x-token-refresh'] != 'undefined' && headers['x-token-refresh'] === "true" ) {
						 $rootScope.$broadcast('event:auth-refresh', response);
					}
				} 
				return response;
			},
			responseError: function(rejection) {
				var rejectionReasons = ['token_not_provided', 'token_expired', 'token_absent', 'token_invalid'];
				 
				// Loop through each rejection reason and redirect to the login
				// state if one is encountered
				var reasonFound = false; 
				angular.forEach(rejectionReasons, function(value, key) { 
					if(rejection.data.error === value) { 
						reasonFound = true;
						 
					}
				});
				if( reasonFound) {
					var deferred = $q.defer();
					httpBuffer.append(rejection.config, deferred);
					$rootScope.$broadcast('event:auth-loginRequired', rejection);
					return deferred.promise;
				}
			  if (!rejection.config.ignoreAuthModule) {
					
				switch (rejection.status) { 
				  case 481:
					var deferred = $q.defer();
					httpBuffer.append(rejection.config, deferred);
					$rootScope.$broadcast('event:auth-passwordRequired', rejection);
					return deferred.promise;
				  case 403:
					$rootScope.$broadcast('event:auth-forbidden', rejection);
					break;
				}
			  }
			  // otherwise, default behaviour
			  return $q.reject(rejection);
			}
		  };
		}]);
		
		
		//Add baseUrl to $http urls that are relative.
		$httpProvider.interceptors.push(['$rootScope', '$q', 'httpBuffer', function($rootScope, $q, httpBuffer) {
		  return {
			request: function(request) {
				// console.log(request);
				if(  typeof request.url !== "undefined" && ! request.cached) {
					if( request.url.match(/^http/i) || request.url.match(/^\//i) || !request.url.match(/^api/i) )
						return request;
					request.url = apiURL + request.url;
				}
				return request;
			}
		  };
		}]);
	}])
	.config(['$stateProvider', '$urlRouterProvider','$locationProvider','$authProvider','$httpProvider',function ($stateProvider, $urlRouterProvider,$locationProvider,$authProvider,$httpProvider){
			// $urlRouterProvider.otherwise("/home");
			$urlRouterProvider.otherwise( function($injector, $location) {
				var $state = $injector.get("$state");
				$state.go("home");
			});
			
			$locationProvider.html5Mode(true); 
			$httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
			
			$stateProvider
			
				//------------------------------
				// HOME
				//------------------------------
			
				.state ('home', { 
					url: '/home',
					templateUrl: 'views/home.html'
				   
				})
				.state('auth', {
					url:'/a',
					templateUrl:'views/auth/common.html',
					params: { noLogin: true }
				})
				.state ('auth.login', {
					url: '/login',
					templateUrl: 'views/auth/login.html',
					 
					params: { noLogin: true }
				   
				})
				.state ('auth.registration', {
					url: '/registration',
					templateUrl: 'views/auth/registration.html',
					 
					params: { noLogin: true }
				   
				})
				.state ('auth.recover', {
					url: '/recover',
					templateUrl: 'views/auth/recover.html', 
					params: { noLogin: true }
				   
				})
				.state ('auth.reset-password', {
					url: '/password/reset/:token',
					templateUrl: 'views/auth/reset.html',
					params: { noLogin: true }
				   
				})
				.state('users',{
					templateUrl: 'views/common.html',
					 url: '/users' 
				})
				.state ('users.index', {
					url: '/index', 
					templateUrl: 'views/users/index.html'
				   
				})
				.state ('users.create', {
					url: '/create',
					templateUrl: 'views/users/editor.html'
				})
				.state ('users.edit', {
					url: '/edit/:id',
					templateUrl: 'views/users/editor.html'
					
				})
				.state ('users.profile', {
					url: '/profile',
					templateUrl: 'views/users/view.html',
					params: { profile:true} 
				})
				.state ('users.profile-edit', {
					url: '/profile/edit',
					templateUrl: 'views/users/editor.html',
					params: { profile:true} 
				})
				.state ('users.show', {
					url: '/show/:id',
					templateUrl: 'views/users/view.html'
				   
				})
				
				.state ('users.roles', {
					url: '/roles', 
					 templateUrl: 'views/clean.html',
				   
				})
				.state ('users.roles.index', {
					url: '/index', 
					templateUrl: 'views/roles/index.html'
				})
				.state ('users.roles.edit', {
					url: '/edit/:id',
					templateUrl: 'views/roles/editor.html'
				})
				.state ('users.roles.create', {
					url: '/create',
					templateUrl: 'views/roles/editor.html' 
				})
				.state ('users.roles.show', {
					url: '/show/:id',
					templateUrl: 'views/roles/show.html'
				   
				})
				
				
				.state('messages',{
					templateUrl: 'views/common.html',
					 url: '/messages' 
				})
				.state('messages.index',{
					templateUrl: 'views/messages/index.html',
					 url: '/index',
				})
				 .state ('messages.view', {
					url: '/view/:id',
					templateUrl: 'views/messages/view.html' 
				})
				.state ('messages.create', {
					url: '/create',
					templateUrl: 'views/messages/create.html'
				   
				})
				
				
				.state('institutions',{
					templateUrl: 'views/common.html',
					 url: '/institutions' 
				})
				.state ('institutions.index', {
					url: '/index', 
					templateUrl: 'views/institutions/index.html'
				   
				})
				.state ('institutions.create', {
					url: '/create',
					templateUrl: 'views/institutions/editor.html'
				})
				.state ('institutions.edit', {
					url: '/edit/:id',
					templateUrl: 'views/institutions/editor.html'
					
				})
				
				.state('units',{
					templateUrl: 'views/common.html',
					 url: '/units' 
				})
				
				.state ('units.index', {
					url: '/index', 
					templateUrl: 'views/units/index.html'
				   
				})
				.state ('units.create', {
					url: '/create',
					templateUrl: 'views/units/editor.html'
				})
				.state ('units.edit', {
					url: '/edit/:id',
					templateUrl: 'views/units/editor.html'
					
				})
				
				.state('services',{
					templateUrl: 'views/common.html',
					 url: '/services' 
				})
				
				.state ('services.index', {
					url: '/services', 
					templateUrl: 'views/services/index.html'
				   
				})
				.state ('services.create', {
					url: '/create',
					templateUrl: 'views/services/editor.html'
				})
				.state ('services.edit', {
					url: '/edit/:id',
					templateUrl: 'views/services/editor.html'
					
				})
				
				
				.state('projects',{
					templateUrl: 'views/common.html',
					 url: '/projects' 
				})
				
				.state ('projects.index', {
					url: '/projects', 
					templateUrl: 'views/projects/index.html'
				   
				})
				.state ('projects.create', {
					url: '/create',
					templateUrl: 'views/projects/editor.html'
				})
				.state ('projects.edit', {
					url: '/edit/:id',
					templateUrl: 'views/projects/editor.html'
					
				})
				.state('needs',{
					templateUrl: 'views/common.html',
					 url: '/project-needs' 
				})
				
				.state ('needs.index', {
					url: '/projects', 
					templateUrl: 'views/projectneeds/index.html'
				   
				})
				.state ('needs.create', {
					url: '/create',
					templateUrl: 'views/projectneeds/editor.html'
				})
				.state ('needs.edit', {
					url: '/edit/:id',
					templateUrl: 'views/projectneeds/editor.html'
					
				})
				
				.state('skills',{
					templateUrl: 'views/common.html',
					 url: '/skills' 
				})
				
				.state ('skills.index', {
					url: '/index', 
					templateUrl: 'views/skills/index.html'
				   
				})
				.state ('skills.create', {
					url: '/create',
					templateUrl: 'views/skills/editor.html'
				})
				.state ('skills.edit', {
					url: '/edit/:id',
					templateUrl: 'views/skills/editor.html'
					
				})
				.state('suitabilities',{
					templateUrl: 'views/common.html',
					 url: '/suitabilities' 
				})
				
				.state ('suitabilities.index', {
					url: '/index', 
					templateUrl: 'views/suitabilities/index.html'
				   
				})
				.state ('suitabilities.create', {
					url: '/create',
					templateUrl: 'views/suitabilities/editor.html'
				})
				.state ('suitabilities.edit', {
					url: '/edit/:id',
					templateUrl: 'views/suitabilities/editor.html'
					
				})
				
				
				.state('requirements',{
					templateUrl: 'views/common.html',
					 url: '/requirements' 
				})
				
				.state ('requirements.index', {
					url: '/index', 
					templateUrl: 'views/requirements/index.html'
				   
				})
				.state ('requirements.create', {
					url: '/create',
					templateUrl: 'views/requirements/editor.html'
				})
				.state ('requirements.edit', {
					url: '/edit/:id',
					templateUrl: 'views/requirements/editor.html'					
				})
				
				.state('volunteers',{
					templateUrl: 'views/common.html',
					 url: '/volunteers' 
				})
				
				.state ('volunteers.index', {
					url: '/index', 
					templateUrl: 'views/volunteers/index.html'
				   
				})
				.state ('volunteers.create', {
					url: '/create',
					templateUrl: 'views/volunteers/editor.html'
				})
				.state ('volunteers.edit', {
					url: '/edit/:id',
					templateUrl: 'views/volunteers/editor.html'					
				})
				
			
	 }]);
function loadKendo($ocLazyLoad,cfpLoadingBar) {
	 
	cfpLoadingBar.start();
	return $ocLazyLoad.load ([
		{
			name: 'vendors',
			files: [
				'js/kendo.js'
			]
		}
	])
};